/* eslint-disable */
<script>
  import { sessionManager } from '@/main';
  import PopupInfo from '@/components/popup-info.vue';
 
  export default {
    name: 'CompanyValuationNetCurrentAssetValueInfo',
    props: {
      onClose: {
        type: Function,
        required: true,
      },
      showPopup: {
        type: Boolean,
        required: true,
      },
    },
    components: {
      PopupInfo,
    },
    data() {
      return {
        languageStrings: {
          en: {
            title: 'Net Current Asset Value (Liquidation Value)',       
            subtitle: 'From "Security Analysis" by Benjamin Graham and David L. Dodd',
            explaination_title: 'Short explaination',
            explaination: 'If a company has 200$ in cash and 100$ in debt, open bills and obligations, how much would you pay for it?',
            explaination2: 'We think the company is worth at least 100$.',
            citation_title: 'How do we get there?',
            citation: 'In their book "Security Analysis" by Benjamin Graham and David L. Dodd the net current asset value is described as a rough indicator for the liquidation value of a company (ISBN 978-3-89879-053-9, Chapter 43: The Significance of Current Asset Value, Page 612ff).',
            citation_how: 'How does it work?',
            citation2: 'The method approximates the liquidation value of a company via the net current asset value. The net current asset value is calculated as the sum of all short-term assets minus all liabilities.',  
          },
          de: {
            title: 'Aktueller Nettovermögenswert (Liquidationswert)',
            subtitle: 'Aus "Geheimnisse der Wertpapier Analyse" von Benjamin Graham und David L. Dodd',
            explaination_title: 'Kurz erklärt',
            explaination: 'Wenn eine Firma 200$ an Bargeld besitz und 100$ an Schulden, offenen Rechnungen und Verplichtungen hat, wieviel würdest du dafür bezahlen?',
            explaination2: 'Wir finden, dass die Firma mindestends 100$ Wert ist.',
            citation_title: 'Wie kommen wir darauf?',
            citation: 'In seinem Buch "Geheimnisse der Wertpapier Analyse" von Benjamin Graham und David L. Dodd ist Beschrieben, dass der Umlaufvermögenswert allgemein ein grober Indikator für den Liquidationswert eine Firma darstellt (ISBN 978-3-89879-053-9, Kapitel 43: Die Bedeutung des Umlaufvermögenswertes, Seite 612ff).',
            citation_how: 'Wie funktioniert die Methode?',
            citation2: 'Die Methode approximiert den Liquidationswert einer Firma über den Umlaufvermögenswert. Der Umlaufvermögenswert berechnet sich als Summe aller kurzfristigen Vermögenswerte abzüglich aller Verbindlichkeiten.',
          }
        },
        language: '',
      };
    },
    created() {
      this.language = sessionManager.read('language');
    },
    computed: {
      strings() {
          return this.languageStrings[this.language];
      },
    }
  }
</script>
  
<template>
  <PopupInfo v-bind:title="strings['title']" v-bind:showPopup="showPopup" v-bind:onClose="onClose">
    <template v-slot:content>
      <div class="text-darkest font-pj text-v-base font-bold py-v-md">{{ strings['explaination_title'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['explaination'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['explaination2'] }}</div>
      <div class="text-darkest font-pj text-v-base font-bold py-v-md">{{ strings['citation_title'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['citation'] }}</div>
      <div class="text-darkest font-pj text-v-base font-bold py-v-md">{{ strings['citation_how'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['citation2'] }}</div>
    </template>
  </PopupInfo>
</template>

/* eslint-enable */ 
  