
<script>
import { sessionManager } from '@/main';

export default {
  name: 'WaitlistUnsubscribe',
  props: {
    email: String,
    code: String,
  },
  data() {
    return {
      languageStrings: {
        en: {
          title: 'Unsubscribed from waitlist',
          headline: 'We are sad to see you go!',
          text: 'You have been removed from the waitlist.',
          text_bye: 'Consider checking our site again in the future.',
        },
        de: {
          title: 'Von der Warteliste abgemeldet',
          headline: 'Wir sind traurig, dass du gehst!',
          text: 'Du bist nun nicht mehr auf der Warteliste.',
          text_bye: 'Schau doch mal wieder auf unserer Seite vorbei.',
        }
      },
      language: '',
    };
  },
  created() {
    this.language = sessionManager.read('language');
    this.fetchData();
  },
  computed: {
    strings() {
        return this.languageStrings[this.language];
    },
  },
  methods: {
    async fetchData () {
      try {
        const url = '/api/HttpTriggerUnregisterWaitlist';
        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: this.email, 
            verificationCode: this.code,
          }),
        }).then((response) => {
        });        
      }
      catch (error) {
        console.log(error);
      }
    },
  }, 
}
</script>

<style>
</style>

<template>
  <div>
    <div class="bg-primary flex justify-center">
      <h2 :class="$vuetify.breakpoint.smAndDown ? 'text-lightest text-v-xl font-pj font-bold py-v-md' : 'text-lightest text-v-xl font-pj font-bold py-v-md'">{{strings['title']}}</h2>
    </div>
    <div :class="$vuetify.breakpoint.smAndDown ? 'px-v-2xl flex justify-center' : 'px-v-4xl flex justify-center'">
      <div class="max-w-4xl pt-v-xl">
          <h3 :class="$vuetify.breakpoint.smAndDown ? 'darkest text-v-xl font-pj font-bold py-v-md' : 'darkest text-v-xl font-pj font-bold py-v-md'">{{strings['headline']}}</h3>
          <p class="darkest text-v-base font-pj">{{ strings['text'] }}</p>
          <p class="darkest text-v-base font-pj">{{ strings['text_bye'] }}</p>
      </div>      
    </div>
  </div>
</template>


