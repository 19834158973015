/* eslint-disable */
<script>
  import { sessionManager } from '@/main';
  import PopupInfo from '@/components/popup-info.vue';
 
  export default {
    name: 'CompanyValuationDCFFreeCashflowInfo',
    props: {
      onClose: {
        type: Function,
        required: true,
      },
      showPopup: {
        type: Boolean,
        required: true,
      },
    },
    components: {
      PopupInfo,
    },
    data() {
      return {
        languageStrings: {
          en: {
            title: 'Discounted Cashflow (DCF)',
            subtitle: 'The standard method for valuing a company',  
            explaination_title: 'A Simplified Overview',
            explaination: 'Imagine acquiring a printer with the capability to generate $100 annually, and this amount increases slightly each year. What would be a reasonable price to pay for it?',
            explaination2: 'Essentially, it’s the total of all the money produced by the printer, brought back to today\'s value through discounting.',
            citation_title: 'How do we get there?',
            citation: 'Wikipedia defines a company’s worth as the total of all its future cash flows, discounted back to their present value (https://en.wikipedia.org/wiki/Discounted_cash_flow).',
            citation_how: 'Understanding the Methodology',
            citation2: 'The process involves projecting a cash flow for the initial year and then assuming a rate of growth for these cash flows in subsequent years.' + 
              ' For each period, we discount the future cash flows back to their present value. The company’s valuation is derived from the sum of all these discounted future cash flows.',
            citation3: 'Determining the Growth Rate g: We utilize the average growth rate from the past five years to make our projections.',
            citation4: 'Setting the Discount Rate d: A 15% discount rate is our standard assumption. However, this is adjustable based on your preferences and risk assessment.',
            citation5: 'Time Horizon: Our analysis spans a decade into the future. Extending this period could result in a higher valuation, while a shorter timeline introduces a margin of safety since we are ignoring the terminal value. Feel free to modify this duration to suit your needs.',
            citation6: 'Considering Net Debt: We account for the company’s net debt, adjusting the total discounted cash flows accordingly.',
          },
          de: {
            title: 'Diskontierter Cashflow (DCF)',
            subtitle: 'Die Standardmethode zur Unternehmensbewertung',
            explaination_title: 'Kurz erklärt',
            explaination: 'Stell dir vor, du kaufst einen Drucker, der jedes Jahr 100 $ druckt und der Betrag jedes Jahr etwas steigt. Wie viel ist er Dir wert?',
            explaination2: 'Im Grunde ist es die Gesamtsumme des gedruckten Geldes, abgezinst auf den heutigen Tag.',
            citation_title: 'Wie funktioniert das?',
            citation: 'Laut Wikipedia entspricht der Unternehmenswert der Summe aller abgezinsten zukünftigen Cashflows (https://en.wikipedia.org/wiki/Discounted_cash_flow).',
            citation_how: 'Die Methode',
            citation2: 'Wir schätzen den Cashflow für das erste Jahr und nehmen eine Wachstumsrate für die Zukunft an.' +
            ' Jeden Cashflow diskontieren wir auf den heutigen Tag. Der Unternehmenswert ergibt sich aus der Summe dieser diskontierten Cashflows.',
           
            citation3: 'Die Wachstumsrate g basiert auf dem Durchschnitt der letzten fünf Jahre.',
            citation4: 'Wir gehen von einem Diskontierungssatz d von 15% aus, den du aber anpassen kannst.',
            citation5: 'Wir blicken 10 Jahre in die Zukunft, wobei eine längere Betrachtung den Unternehmenswert erhöht und eine kürzere Betrachtung die Sicherheitsmarge der Bewertung vergrössert da wir den Terminalwert des Unternehmens nicht berücksichtigen. Du kannst diesen Zeitraum nach Belieben anpassen.',
            citation6: 'Die Nettoverschuldung des Unternehmens wird berücksichtigt und nach der Summierung aller diskontierten Cashflows hinzugefügt bzw. abgezogen.',
          }
        },

        language: '',
      };
    },
    created() {
      this.language = sessionManager.read('language');
    },
    computed: {
      strings() {
          return this.languageStrings[this.language];
      },
    }
  }
</script>
  
<template>
  <PopupInfo v-bind:title="strings['title']" v-bind:showPopup="showPopup" v-bind:onClose="onClose">
    <template v-slot:content>
      <div class="text-darkest font-pj text-v-base font-bold py-v-md">{{ strings['explaination_title'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['explaination'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['explaination2'] }}</div>
      <div class="text-darkest font-pj text-v-base font-bold py-v-md">{{ strings['citation_title'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['citation'] }}</div>
      <div class="text-darkest font-pj text-v-base font-bold py-v-md">{{ strings['citation_how'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['citation2'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">
          DCF = Cashflow (1 + g)<sup>1</sup> / (1 + d)<sup>1</sup> + Cashflow (1 + g)<sup>2</sup> / (1 + d)<sup>2</sup> + Cashflow (1 + g)<sup>3</sup> / (1 + d)<sup>3</sup> + ...
      </div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['citation3'] }}<br>{{ strings['citation4'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['citation5'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['citation6'] }}</div>
    </template>
  </PopupInfo>
</template>

/* eslint-enable */ 
  