import { render, staticRenderFns } from "./company-valuation-net-current-asset-value-info.vue?vue&type=template&id=33d98d90"
import script from "./company-valuation-net-current-asset-value-info.vue?vue&type=script&lang=js"
export * from "./company-valuation-net-current-asset-value-info.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports