/* eslint-disable */
<script>
  import { sessionManager } from '@/main';
  import PopupInfo from '@/components/popup-info.vue';
 
  export default {
    name: 'CompanyValuationMarginOfsafetyInfo',
    props: {
      onClose: {
        type: Function,
        required: true,
      },
      showPopup: {
        type: Boolean,
        required: true,
      },
    },
    components: {
      PopupInfo,
    },
    data() {
      return {
        languageStrings: {
          en: {
            title: 'Margin of safety Valuation',
            subtitle: 'From "Invested" by Danielle Town & Phil Town',
            earnings: 'Earnings',   
            growth: 'Estimated Earnings growth rate',
            future_earnings: 'Future earnings',
            future_pe: 'Future P/E value',
            future_market_cap: 'Expected Future Market Cap',
            discount_rate: 'Discount Rate',
            discounted_valuation: 'Discounted Market Cap',
            net_debt: 'Net Debt',
            net_cash: 'Net Cash',
            valuation: 'Valuation',
            valuation_ratio: 'Price / Valuation',
            valuation_adjusted: 'Valuation adjusted for Cash/Debt',
            valuation_safety: 'Valuation with safety Margin',

            explaination_title: 'Short Explaination',
            explaination: 'Imagine you buy a printer that prints 100$ this year and every year a little bit more. How much would you pay for it?',
            explaination2: 'We think about how much the printer will print in 10 years. Then we estimate how much we would pay for that money in the future. Then we think about how much we would pay for that money today.',  

            citation_title: 'How did we get there?',
            citation: 'In their book "Invested - How Warren Buffett and Charlie Munger taught me to master my mind, my emotions, and my money (with a little help from my dad)" '+
              'by Danielle Town and Phil Town the valuation method "Margin of safety Valuation" is described (ISBN 978-3-86470-604-2 Kapiel 8: Charlies viertes Prinzip: Value, Die Sicherheitsmargen-Bewertungsmethode, page 251ff).',
            citation_how: 'How does it work?',  
            citation2: 'The Margin of safety Valuation is based on the earnings and the estimated earnings growth rate. Based on the estimated earnings growth rate, the earnings in 10 years are calculated. ' +
              'Based on the historical P/E ratio, the expected market cap in 10 years is calculated (for P/E > 25, 25 is assumed).',
            citation3: 'Since money in the future is worth less than money today, the expected market cap is discounted back to the present with a discount rate. Then debt is subtracted.',
            citation4: 'The valuation of the company is discounted with a margin of safety of 50%. This means that we would only pay half of the calculated value.',
          },
          de: {
            title: 'Sicherheitsmargen-Bewertung',
            subtitle: 'Aus "Gut Investiert" von Danielle Town & Phil Town',
            earnings: 'Gewinn',
            growth: 'Geschätzters Gewinnwachstum',
            future_earnings: 'Zukünftiger Gewinn',
            future_pe: 'Zukünftiges Kurs/Gewinn Verhältnis (KGV)',
            future_market_cap: 'Zukünftige Markkapitalisierung',
            discount_rate: 'Diskontierungsrate',
            discounted_valuation: 'Discontierte Marktkapitalisierung',
            net_debt: 'Nettoverschuldung',
            net_cash: 'Nettobarvermögen',
            valuation: 'Bewertung',
            valuation_ratio: 'Preis / Bewertung',
            valuation_adjusted: 'Bewertung nach Vermögen/Schulden',
            valuation_safety: 'Bewertung mit Sicherheitsmarge',

            explaination_title: 'Kurz erklärt',
            explaination: 'Stell dir vor, es du kaufst einen Drucker der dieses Jahr 100$ druckt und jedes Jahr mit einer Wachstumsrate etwas mehr. Wie viel würdest Du dafür bezahlen?',
            explaination2: 'Wir überlegen uns vieviel der Drucker in 10 Jahren pro Jahr drucken wird. Dann schätzen wir vieviel dafür in der Zukunft bezahlt würde. Dann überlegen wir uns wieviel wir heute für das Geld in 10 Jahren zahlen würden.',

            citation_title: 'Wie kommen wir darauf?',

            citation: 'In ihrem Buch "Gut Investiert - Wie Warren Buffett und Charlie Munger (und Paps*) mich lehrten, meine Gedanken, meine Emotionen und mein Geld unter Kontrolle zu bekommen" '+
              'von Danielle Town und Phil Town ist die Bewertungsmethode "Sicherheitsmargen-Bewertungsmethode" beschrieben (ISBN 978-3-86470-604-2 Kapiel 8: Charlies viertes Prinzip: Value, Die Sicherheitsmargen-Bewertungsmethode, Seite 251ff).',

            citation_how: 'Wie funktioniert die Methode?',
            citation2: 'Die Sicherheitsmargen-Bewertung basiert auf dem Gewinn und dem Gewinnwachstum. Dabei wird, basierend auf einem geschätzen Gewinnwachstum, errechnet, ' + 
            'welchen Gewinn das Unternehmen in 10 Jahren haben könnte. Gemessen am historischen Kurs/Gewinn Verhältnis (KGV) errechnet sich daraus eine erwartete Marktkapitalisierung in 10 Jahren (für KGV > 25 wird 25 angenommen). ',
            citation3: 'Da Geld in der Zukunft weniger wert ist als Geld heute, diskonieren wir die erwartete Marktkapitalisierung mit einer Diskontierungsrate zurück in die Gegenwart. Anschliessend werden Schulden abgezogen.',
            citation4: 'Die so errechnete Bewertung des Unternehmens wird mit einer Sicherheitsmarge von 50% bewertet. Das heisst, dass wir nur die Hälfte des errechneten Wertes bezahlen würden.',
          }
        },
        language: '',
      };
    },
    created() {
      this.language = sessionManager.read('language');
    },
    computed: {
      strings() {
          return this.languageStrings[this.language];
      },
    },
  }
</script>
  

<template>
  <PopupInfo v-bind:title="strings['title']" v-bind:showPopup="showPopup" v-bind:onClose="onClose">
    <template v-slot:content>
      <div class="text-darkest font-pj text-v-base font-bold py-v-md">{{ strings['explaination_title'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['explaination'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['explaination2'] }}</div>
      <div class="text-darkest font-pj text-v-base font-bold py-v-md">{{ strings['citation_title'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['citation'] }}</div>
      <div class="text-darkest font-pj text-v-base font-bold py-v-md">{{ strings['citation_how'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['citation2'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['citation3'] }}</div> <meta http-equiv="refresh" content="5">
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['citation4'] }}</div>
    </template>
  </PopupInfo>
</template>

/* eslint-enable */ 
  