/* eslint-disable */
<script>
  import { sessionManager } from '@/main';
  import PopupInfo from '@/components/popup-info.vue';
 
  export default {
    name: 'CompanyValuationTenCapEarningsInfo',
    props: {
      onClose: {
        type: Function,
        required: true,
      },
      showPopup: {
        type: Boolean,
        required: true,
      },
    },
    components: {
      PopupInfo,
    },
    data() {
      return {
        languageStrings: {
          en: {
            title: 'Payback-Time Owner Earnings',   
            subtitle: 'Inspired by "Invested" by Danielle Town & Phil Town',
            explaination_title: 'Short explaination',
            explaination: 'Imagine you buy a printer that prints 100$ this year and every year a little bit more. How much would you pay for it?',
            explaination2: 'We think the printer is worth the money it will print over the next 8 years.',
            citation_title: 'How did we come up with this?',
            citation: 'In their book "Invested - How Warren Buffett and Charlie Munger taught me to master my mind, my emotions, and my money (with a little help from my dad)" '+
              'by Danielle Town and Phil Town the valuation method "Payback-Time Method" is described (ISBN 978-3-86470-604-2 Kapiel 7: Charlies viertes Prinzip: Value, Die Payback-Time Methode, Page 242-249).',
            citation_how: 'How does it work?',
            citation2: 'The Payback-Time Method is based on the free cashflow and focuses on how many years it takes to pay back the initial investment.',
            citation3: 'We estimate a growth rate for the free cashflow and cumulate it over the next 8 years. The resulting number is the payback-time valuation of the company.',
            citation3: 'Unlike the payback-time method of the free cashflow, we use the owner earnings instead of the free cashflow and subtract the net debt at the end. ',
            citation4: 'We deviate from the original method because we believe that a valuation based on the owner earnings provides additional insight into the value of a company.',
            citation5: 'Owner earnings is a term coined by Warren Buffet, which essentially means net income with depreciation and amortization added back, from which maintenance capex is then subtracted.',
            citation6: 'Only the maintenance capex that is used to maintain the company is subtracted. ' +
              'Since the breakdown of maintenance capex into maintenance and growth is not part of the usual financial reporting, it is initially assumed that 70% of the total investment is necessary to maintain the company. ' +
              'If you have a more precise idea of the breakdown, you should adjust this value.',
          },
          de: {
            title: 'Payback-Time Owner Earnings',
            subtitle: 'Inspiriert durch "Gut Investiert" von Danielle Town & Phil Town',
            explaination_title: 'Kurz erklärt',
            explaination: 'Stell dir vor, es du kaufst einen Drucker der dieses Jahr 100$ druckt und jedes Jahr mit einer Wachstumsrate etwas mehr. Wie viel würdest Du dafür bezahlen?',
            explaination2: 'Wir finden, dass der Drucker das Geld wert ist, das er über die nächsten 8 Jahre drucken wird.',
            citation_title: 'Wie kommen wir darauf?',
            citation: 'In ihrem Buch "Gut Investiert - Wie Warren Buffett und Charlie Munger (und Paps*) mich lehrten, meine Gedanken, meine Emotionen und mein Geld unter Kontrolle zu bekommen" '+
              'von Danielle Town und Phil Town ist die Bewertungsmethode "Payback-Time Methode" beschrieben (ISBN 978-3-86470-604-2 Kapiel 7: Charlies viertes Prinzip: Value, Die Payback-Time Methode, Seite 242-249).',
            citation_how: 'Wie funktioniert die Methode?',
            citation2: 'Die Payback-Time Methode basiert auf dem freien Cashflow und fokusiert sich darauf wieviele Jahre es dauert im die Anfangsinvestition wieder amortisiert zu haben.',
            citation3: 'Dabei schätzen wir eine Wachstumsrate für den freien Cashflow und kummulieren diesen über die nächsten 8 Jahre. Die so errechnete Zahl ist die Payback-Time Bewertung des Unternehmens.',
            citation3: 'Anders als bei der Payback-Time Methode des Freien Cashflows verwenden wir bei der Payback-Time Methode der Owner Earnings die Eigentümergewinns anstellele des Freien Cashflows und ziehen am Ende die Nettoverschuldung ab. ',
            citation4: 'Dabei weichen wir von der ursprünglichen Methode ab, da wir der Meinung sind, dass eine Bewertung aufgrund des der Eigentümergewinne (Owner Earnings) zusätzlichen Einblick in den Wert eines Unternehmens bietet.',
            citation5: 'Eigentümergewinn oder Owner Earnings, ist ein Begriff, den Warren Buffett geprägt hat, der im Wesentlichen den Nettogewinn mit wieder hinzugefügter Abschreibung und Amortisation meint, von dem dann die Instandhaltungskosten/Kapitalkosten abgezogen werden.',
            citation6: 'Dabei werden lediglich die Instandhaltungskosten/Kapitalkosten abgezogen die für den Erhalt des Unternehmens verwendet werden. ' +
              'Da die Aufteilung der Instandhaltungskosten/Kapitalkosten auf Instandhaltung und Wachstum nicht Teil des üblichen Finanz-Reportings sind, wird inital angenommen, dass 70% der Gestammtinvestitionen für den Erhalt des Unternehmens notwendig ist. ' +
              'Wenn Du eine genauere Vorstellung über die Aufteilung hast, solltest Du diesen Wert anpassen.',
          }
        },
        language: '',
      };
    },
    created() {
      this.language = sessionManager.read('language');
    },
    computed: {
      strings() {
          return this.languageStrings[this.language];
      },
    }
  }
</script>
  
<template>
  <PopupInfo v-bind:title="strings['title']" v-bind:showPopup="showPopup" v-bind:onClose="onClose">
    <template v-slot:content>
      <div class="text-darkest font-pj text-v-base font-bold py-v-md">{{ strings['explaination_title'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['explaination'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['explaination2'] }}</div>
      <div class="text-darkest font-pj text-v-base font-bold py-v-md">{{ strings['citation_title'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['citation'] }}</div>
      <div class="text-darkest font-pj text-v-base font-bold py-v-md">{{ strings['citation_how'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['citation2'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['citation3'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['citation4'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['citation5'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['citation6'] }}</div>
    </template>
  </PopupInfo>
</template>
/* eslint-enable */ 
  