/* eslint-disable */
<script>

// https://vue-chartjs.org/examples/

import { Line as LineChartGenerator } from 'vue-chartjs/legacy'

import { Colors } from '../colors';
import { sessionManager } from '@/main';
import PopupSaveContainer from '@/components/popup-save-container.vue';
import { getYearAndQuarter, getYear
 } from '../helper';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
)
import { formatUnitOfAccountChart } from '../helper';


export default {
  name: 'CompanyValuationHistorical',
  props: {
    valuationData: {
      required: true,
      default: () => null,
    },
    marketCapData: {
      required: true,
      default: () => null,
    },
    unitOfAccount: {
      required: true,
      default: () => 'USD',
    },
    companyName: {
      type: String,
      default: () => ''
    },
    tweet: {
      type: Boolean,
      required: false,
      default: () => false,
    }
  },
  components: {
    LineChartGenerator,
    PopupSaveContainer,
  },
  data() {
    return {
      plugins: [],
      styles: {},
      cssClasses: '',
      datasetIdKey: 'label',
      chartData: {},
      chartOptions: {},
      chartOptionsPopup: {},
      languageStrings: {
        en: {
          title: 'Historical Valuations',      
          ten_cap_earnings: 'Ten-Cap Earnings',
          ten_cap_fcf: 'Ten-Cap Free Cashflow',
          ten_cap_oe: 'Ten-Cap Owner Earnings',
          margin_of_safety: 'Margin of safety Valuation',
          payback_fcf: 'Payback Time Free Cashflow',
          payback_oe: 'Payback Time Owner Earnings',
          graham_number: 'Graham Number',
          net_current_asset: 'Net Current Asset Value Valuation',
          market_cap: 'Market Capitalization',
          dcf_free_cashflow: 'Discounted Cashflow',
        },
        de: {
          title: 'Historische Bewertungen',
          ten_cap_earnings: 'Ten-Cap Gewinn',
          ten_cap_fcf: 'Ten-Cap Freier Cashflow',
          ten_cap_oe: 'Ten-Cap Owner Earnings',
          margin_of_safety: 'Sicherheitsmargen-Bewertung',
          payback_fcf: 'Payback-Time Freier Cashflow',
          payback_oe: 'Payback-Time Owner Earnings',
          graham_number: 'Graham Nummer',
          net_current_asset: 'Aktueller Nettovermögenswert',
          market_cap: 'Marketkapitalisierung',
          dcf_free_cashflow: 'Diskontierter Cashflow',
        }
      },
      language: '',
      chartKey: 0,
      width: 200,
      height: 100,
      resizeObserver: null,
    };
  },
  created() {
    this.language = sessionManager.read('language');
  },
  computed: {
    strings() {
        return this.languageStrings[this.language];
    },
  },
  methods: {
    // transforms the data into the expected structure of the bar component
    initializeData() {
      this.chartData.datasets = [];
      this.chartData.labels = [];
      this.chartData.backgroundColor = [];

      var tenCapEarningsValuation = [];
      var tenCapFreeCashflowValuation = [];
      var tenCapOwnerEarningsValuation = [];
      var marginOfsafetyValuation = [];
      var paybackTimeValuationFreeCashflow = [];
      var paybackTimeValuationOwnerEarnings = [];
      var netCurrentAssetValueValuation = [];
      var grahamNumberValuation = [];
      var dcfFreeCashflowValuation = [];
      var marketCap = [];
      
      if(!this.valuationData)
      {
        return;
      }

      let length = 40; // 10 years of quarters
      let quarters = 1; 

      let dates = [];

      // ten cap of earnings
      let i = 0; 
      this.valuationData.tenCapEarningsValuation.forEach(element => {
        if(i < length)
        {
          if(i%quarters == 0) 
          { 
            tenCapEarningsValuation.push(element.valuation);        
            let label = getYearAndQuarter(element.financialPeriod);
            
            dates.push(element.financialPeriod);
            this.chartData.labels.push(label);
          }
        }
        i++;
      });
      tenCapEarningsValuation.reverse();
      this.chartData.labels.reverse();
      dates.reverse();

      // ten cap of free cash flow
      i = 0; 
      this.valuationData.tenCapFreeCashflowValuation.forEach(element => {
        if(i < length)
        {
          if(i%quarters == 0) 
          { 
            tenCapFreeCashflowValuation.push(element.valuation);        
          }
        }
        i++;
      });
      tenCapFreeCashflowValuation.reverse();

      // ten cap of owner earnings
      i = 0; 
      this.valuationData.tenCapOwnerEarningsValuation.forEach(element => {
        if(i < length)
        {
          if(i%quarters == 0) 
          { 
            tenCapOwnerEarningsValuation.push(element.valuation);        
          }
        }
        i++;
      });
      tenCapOwnerEarningsValuation.reverse();

      // marginOfsafetyValuation
      i = 0;
      this.valuationData.mosValuation.forEach(element => {
        if(i < length)
        {
          if(i%quarters == 0) 
          {
            marginOfsafetyValuation.push(element.mosValuation);       
          }          
        }
        i++;
      });
      marginOfsafetyValuation.reverse();

      // paybackTimeValuation
      i = 0; 
      this.valuationData.paybackTimeValuationFreeCashflow.forEach(element => {
        if(i < length)
        {
          if(i%quarters == 0)  // only one per year
          {
            paybackTimeValuationFreeCashflow.push(element.valuation);
          }
        }
        i++;
      });
      paybackTimeValuationFreeCashflow.reverse();

      // paybackTimeValuation
      i = 0; 
      this.valuationData.paybackTimeValuationOwnerEarnings.forEach(element => {
        if(i < length)
        {
          if(i%quarters == 0)  // only one per year
          {
            paybackTimeValuationOwnerEarnings.push(element.valuation);
          }
        }
        i++;
      });
      paybackTimeValuationOwnerEarnings.reverse();

      // netCurrentAssetValueValuation
      i = 0; 
      this.valuationData.netCurrentAssetValueValuation.forEach(element => {
        if(i < length)
        {
          if(i%quarters == 0)  // only one per year
          {
            netCurrentAssetValueValuation.push(element.valuation);
          }
        }
        i++;
      });
      netCurrentAssetValueValuation.reverse();

      // graphamNumberValuation
      i = 0;  
      this.valuationData.grahamNumberValuation.forEach(element => {
        if(i < length)
        {
          if(i%quarters == 0)  // only one per year
          {
            grahamNumberValuation.push(element.valuation);
          }
        }
        i++;
      });
      grahamNumberValuation.reverse();

      // dcfFreeCashflowValuation
      i = 0; 
      this.valuationData.dcfValuationFreeCashflow.forEach(element => {
        if(i < length)
        {
          if(i%quarters == 0)  // only one per year
          {
            dcfFreeCashflowValuation.push(element.valuation);
          }
        }
        i++;
      });
      dcfFreeCashflowValuation.reverse();

      
      // search the corresponding market cap (the one closest to the existing date)
      dates.forEach(element => {
        // find the closest date
        var value = 0;
        var minDiff = Number.MAX_SAFE_INTEGER;
        var uxChartDate = Date.parse(element);
        this.marketCapData.forEach(mk => {
          var mkDate = Date.parse(mk.date);
          var diff = uxChartDate-mkDate;
          if(diff < 0)
          {
            diff = -diff;
          }
          if(diff < minDiff)
          {
            minDiff = diff;
            value = mk.marketCap;
          }
        });
        marketCap.push(value);
      });
      
      this.chartData.datasets = [
        {
          label: this.strings['ten_cap_earnings'],
          data: tenCapEarningsValuation,
          backgroundColor: Colors.GreenOk,
          borderColor: Colors.GreenOk,
          borderWidth: 2, 
          tension: 0.1,
        },
        
        {
          label: this.strings['ten_cap_fcf'],
          data: tenCapFreeCashflowValuation,
          backgroundColor: Colors.Blue5,
          borderColor: Colors.Blue5,
          borderWidth: 2, 
          tension: 0.1,
        },
      {
          label: this.strings['ten_cap_oe'],
          data: tenCapOwnerEarningsValuation,
          backgroundColor: Colors.Orange2,
          borderColor: Colors.Orange2,
          borderWidth: 2, 
          tension: 0.1,
        },
        {
          label: this.strings['margin_of_safety'],
          data: marginOfsafetyValuation,
          backgroundColor: Colors.GreenOKLight,
          borderColor: Colors.GreenOKLight,
          borderWidth: 2, 
          tension: 0.1,
        },
        {
          label: this.strings['payback_fcf'],
          data: paybackTimeValuationFreeCashflow,
          backgroundColor: Colors.Blue9,
          borderColor: Colors.Blue9,
          borderWidth: 2, 
          tension: 0.1,
        },
        {
          label:  this.strings['payback_oe'],
          data: paybackTimeValuationOwnerEarnings,
          backgroundColor: Colors.Orange,
          borderColor: Colors.Orange,
          borderWidth: 2, 
          tension: 0.1,
        },
        {
          label: this.strings['net_current_asset'],
          data: netCurrentAssetValueValuation,
          backgroundColor: Colors.Gray,
          borderColor: Colors.Gray,
          borderWidth: 2, 
          tension: 0.1,
        },
        {
          label: this.strings['graham_number'],
          data: grahamNumberValuation,
          backgroundColor: Colors.PastelGreen,
          borderColor: Colors.PastelGreen,
          borderWidth: 2, 
          tension: 0.1,
        },
        {
          label: this.strings['dcf_free_cashflow'],
          data: dcfFreeCashflowValuation,
          backgroundColor: Colors.Blue5,
          borderColor: Colors.Blue3,
          borderWidth: 2, 
          tension: 0.1,
        },
        {
          label: this.strings['market_cap'],
          data: marketCap,
          backgroundColor: 'rgb(100, 100, 100)',
          borderColor: 'rgb(100, 100, 100)',
          borderWidth: 2.5, 
          tension: 0.1,
        },
      ];

      // normal chart options
      this.chartOptions = {
        scales: {
          y: {
              ticks: {
                  // Include a dollar sign in the ticks
                  callback: (value, index, ticks)  => {
                      return formatUnitOfAccountChart(value, this.unitOfAccount, this.language);
                  }
              }
          }
        },
        elements: {
          point: {
            radius: 1
          }
        },
        responsive: true,
      };

      // chart options for the popup
      this.chartOptions_popup = {
        scales: {
          y: {
              ticks: {
                  // Include a dollar sign in the ticks
                  callback: (value, index, ticks)  => {
                      return formatUnitOfAccountChart(value, this.unitOfAccount, this.language);
                  }
              }
          }
        },
        elements: {
          point: {
            radius: 1
          }
        },
        responsive: true,
      };
    },
    adjustChartHeight() {
      const widthThreshold = 300;
      const widthThreshold2 = 380;      

      if (this.$refs && this.$refs.chartContainer) {
        const chartContainer = this.$refs.chartContainer;
        if (chartContainer.offsetWidth < widthThreshold) {
          this.width = 100;
          this.height = 100;
        } 
        else if(chartContainer.offsetWidth < widthThreshold2) {
          this.width = 150;
          this.height = 100;
        }
        else {
          this.width = 200;
          this.height = 100;
        }
      }

      this.chartKey += 1;
    },
    updateChartOptions() {
      const isMobile = window.innerWidth < 380; // You can adjust the width as per your need
      let showLegend = true;
      if(this.$refs.companyValuationHistoricalRef)
      {
        let height = this.$refs.companyValuationHistoricalRef.clientHeight;
        showLegend = height > 250; // You can adjust the width as per your need
      }      
      this.chartOptions = {
        scales: {
          y: {
            ticks: {
              display: !isMobile, // Hide on mobile screens
              callback: (value) => {
                return formatUnitOfAccountChart(value, this.unitOfAccount, this.language);
              }
            }
          }
        },
        plugins: {
            legend: {
              display: showLegend
            }
        },
        elements: {
          point: {
            radius: 1
          }
        },
        responsive: true,
      };
    },
  },
  beforeMount() {
    this.initializeData();
  },
  mounted() {
    this.resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        this.updateChartOptions()
      }
    });
    const chartContainer = this.$refs.companyValuationHistoricalRef;
    if (chartContainer) {
      this.resizeObserver.observe(chartContainer);
    }

    this.updateChartOptions();
    window.addEventListener('resize', this.updateChartOptions); 

    this.$nextTick(() => {
      // Check if the ref is available and then adjust the height
      if (this.$refs.companyValuationHistoricalRef) {
        this.adjustChartHeight();
      }
      window.addEventListener('resize', this.adjustChartHeight);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.adjustChartHeight);
    window.removeEventListener('resize', this.updateChartOptions);
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
}
</script>

<template>
  <div>
    <div v-if="tweet">
      <div class="flex-auto">
        <h3 class="darkest font-pj text-v-xl text-bold" style="text-align:left;float:left;">
          {{ companyName }} {{ strings['title'] }}
        </h3>
      </div>
      <LineChartGenerator
        :chart-options="chartOptions"
        :chart-data="chartData"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height"
      />
      <div>
        <div class="flex justify-end">
          <div class="flex-none text-v-base font-pj pt-v-md"><svg width="15x" height="15px" viewBox="0 0 7.9375 7.9375" version="1.1" id="svg5" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"><defs id="defs2"><rect x="30" y="486" width="372.5" height="75.259857" id="rect1465-7-8" /></defs><g id="layer1" transform="translate(195.79166,-445.55834)"><circle style="fill:#71b224;fill-opacity:1;stroke-width:0.296467;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:6.8" id="path1525-7" cx="-191.82291" cy="449.52707" r="3.96875" /><g aria-label="v" transform="matrix(0.30397883,0,0,0.30397907,-203.69511,295.47429)" id="text1463-6-5" style="font-weight:bold;font-size:32px;line-height:1.25;font-family:'Plus Jakarta Sans';-inkscape-font-specification:'Plus Jakarta Sans Bold';letter-spacing:0px;word-spacing:0px;white-space:pre;shape-inside:url(#rect1465-7-8);display:inline;fill:#ffffff"><path d="m 37.2,516.3619 -6.848,-17.408 h 4.576 l 4.96,13.696 h -1.696 l 4.992,-13.696 h 4.576 l -6.848,17.408 z" id="path1322" /></g></g></svg>
          </div>
          <div class="flex-none text-v-base font-pj pt-v-md pl-v-sm"><b>value</b>tion</div>
        </div>
      </div>      
    </div>
    <PopupSaveContainer v-if="!tweet" v-bind:title="strings['title']" v-bind:companyName="companyName" :popup="true">
      <template v-slot:content>
        <div ref="companyValuationHistoricalRef">
          <LineChartGenerator
          :chart-options="chartOptions"
          :chart-data="chartData"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
          :height="height"
        />
        </div> 
      </template>  
      <template v-slot:content_popup>
        <div ref="companyValuationHistoricalRef">
          <LineChartGenerator
          :chart-options="chartOptions_popup"
          :chart-data="chartData"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
          :height="height"
        />
        </div> 
      </template>  
    </PopupSaveContainer>
  </div>  
</template>


/* eslint-enable */ 