/* eslint-disable */
<script>
  import { sessionManager } from '@/main';
  import PopupInfo from '@/components/popup-info.vue';
 
  export default {
    name: 'CompanyValuationPaybackTimeFreeCashflowInfo',
    props: {
      onClose: {
        type: Function,
        required: true,
      },
      showPopup: {
        type: Boolean,
        required: true,
      },
    },
    components: {
      PopupInfo,
    },
    data() {
      return {
        languageStrings: {
          en: {
            title: 'Payback-Time Free Cashflow',      
            subtitle: 'From "Invested" by Danielle Town & Phil Town',
            fcf: 'Free Cashflow',   
            payback: 'Payback after 8 years',
            growth: 'Estimated growth rate',
            net_debt: 'Net Debt',
            net_cash: 'Net Cash',
            valuation: 'Valuation',
            valuation_ratio: 'Price / Valuation',
            explaination_title: 'Short explaination',
            explaination: 'Imagine you buy a printer that prints 100$ this year and every year a little bit more. How much would you pay for it?',
            explaination2: 'We think the printer is worth the money it will print over the next 8 years.',
            citation_title: 'How do we get there?',
            citation: 'In their book "Invested - How Warren Buffett and Charlie Munger taught me to master my mind, my emotions, and my money (with a little help from my dad)" '+
              'by Danielle Town and Phil Town the valuation method "Payback-Time Method" is described (ISBN 978-3-86470-604-2 Kapiel 7: Charlies viertes Prinzip: Value, Die Payback-Time Methode, Page 242-249).',
            citation_how: 'How does it work?',
            citation2: 'The Payback-Time Method is based on the free cashflow and focuses on how many years it takes to pay back the initial investment.',
            citation3: 'We estimate a growth rate for the free cashflow and cumulate it over the next 8 years. The resulting number is the payback-time valuation of the company.',
            citation4: 'Differing from the original method by Danielle Town and Phil Town, we take the net debt of the company into account.',
          },
          de: {
            title: 'Payback-Time Freier Cashflow',
            subtitle: 'Aus "Gut Investiert" von Danielle Town & Phil Town',
            fcf: 'Freier Cashflow',
            payback: 'Amortisation nach 8 Jahren',
            growth: 'Geschätzes Wachstumsrate',
            net_debt: 'Nettoverschuldung',
            net_cash: 'Nettobarvermögen',
            valuation: 'Bewertung',
            valuation_ratio: 'Preis / Bewertung',       
            explaination_title: 'Kurz erklärt',
            explaination: 'Stell dir vor, es du kaufst einen Drucker der dieses Jahr 100$ druckt und jedes Jahr mit einer Wachstumsrate etwas mehr. Wie viel würdest Du dafür bezahlen?',
            explaination2: 'Wir finden, dass der Drucker das Geld wert ist, das er über die nächsten 8 Jahre drucken wird.',
            citation_title: 'Wie kommen wir darauf?',
            citation: 'In ihrem Buch "Gut Investiert - Wie Warren Buffett und Charlie Munger (und Paps*) mich lehrten, meine Gedanken, meine Emotionen und mein Geld unter Kontrolle zu bekommen" '+
              'von Danielle Town und Phil Town ist die Bewertungsmethode "Payback-Time Methode" beschrieben (ISBN 978-3-86470-604-2 Kapiel 7: Charlies viertes Prinzip: Value, Die Payback-Time Methode, Seite 242-249).',
            citation_how: 'Wie funktioniert die Methode?',
            citation2: 'Die Payback-Time Methode basiert auf dem freien Cashflow und fokusiert sich darauf wieviele Jahre es dauert im die Anfangsinvestition wieder amortisiert zu haben.',
            citation3: 'Dabei schätzen wir eine Wachstumsrate für den freien Cashflow und kummulieren diesen über die nächsten 8 Jahre. Die so errechnete Zahl ist die Payback-Time Bewertung des Unternehmens.',
            citation4: 'Abweichend von der Originalmethode von Danielle Town und Phil Town, nehmen wir die Netto-Verschuldung des Unternehmens mit in die Bewertung auf.',
          }
        },
        language: '',
      };
    },
    created() {
      this.language = sessionManager.read('language');
    },
    computed: {
      strings() {
          return this.languageStrings[this.language];
      },
    }
  }
</script>

<template>
  <PopupInfo v-bind:title="strings['title']" v-bind:showPopup="showPopup" v-bind:onClose="onClose">
    <template v-slot:content>
      <div class="text-darkest font-pj text-v-base font-bold py-v-md">{{ strings['explaination_title'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['explaination'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['explaination2'] }}</div>
      <div class="text-darkest font-pj text-v-base font-bold py-v-md">{{ strings['citation_title'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['citation'] }}</div>
      <div class="text-darkest font-pj text-v-base font-bold py-v-md">{{ strings['citation_how'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['citation2'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['citation3'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['citation4'] }}</div>
    </template>
  </PopupInfo>
</template>
  
/* eslint-enable */ 
  