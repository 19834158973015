/* eslint-disable */
<script>
  import { sessionManager } from '@/main';
  import PopupInfo from '@/components/popup-info.vue';
 
  export default {
    name: 'CompanyValuationPeterLynchPEGRatioInfo',
    props: {
      onClose: {
        type: Function,
        required: true,
      },
      showPopup: {
        type: Boolean,
        required: true,
      },
    },
    components: {
      PopupInfo,
    },
    data() {
      return {
        languageStrings: {
          en: {
            title: 'PEG Ratio',   
            subtitle: 'Inspired by "Invested" by Danielle Town & Phil Town',
            explaination_title: 'Short explaination',
            explaination: 'Imagine you buy a printer that prints $100 this year and prints more money every year at a known growth rate. How much would you pay for it?',
            explaination2: 'We think that if the printer grows at 10% it is worth $1\'000 and if it grows at 20% it is worth $2\'000.',
            citation_title: 'How do we get there?',
            citation: 'In his book "One Up On Wall Street" by Peter Lynch the valuation method "Price to Earnings Growth (PEG) Ratio" is described (ISBN 978-3-86470-565-6 Chapter 13: Some Famous Numbers, Page 252).',
            citation_how: 'How does it work?',
            citation2: 'According to Peter Lynch: "The PEG ratio of any correctly priced common stock equals its growth rate". The Price to Earnings Growth (PEG) Ratio is the Price to Earnings (PE) Ratio divided by the growth rate.',
            citation3: 'This gives us a simple metric that tells us if a company is fairly valued or not. The net debt is not taken into account.',
          },
          de: {
            title: 'Peter Lynch Kurs-Gewinn-Wachstums-Verhältnis',
            subtitle: 'Aus "Der Börse einen Schritt voraus" von Peter Lynch',
            explaination_title: 'Kurz erklärt',
            explaination: 'Stell dir vor, es du kaufst einen Drucker der dieses Jahr 100$ druckt und jedes Jahr mit einer Wachstumsrate mehr Geld druckt. Wie viel würdest Du dafür bezahlen?',
            explaination2: 'Wir finden, dass wenn der Drucker mit 10% wächst, dieser 1\'000$ Wert ist und wenn er mit 20% wächst 2\'000$.',
            citation_title: 'Wie kommen wir darauf?',
            citation: 'In "Der Börse einen Schritt Voraus" von Peter Lynch ist die Bewertungsmethode "Kurs-Gewinn-Wachstums-Verhältnis" beschrieben (ISBN 978-3-86470-565-6 Kapitel 13: Die Wichtigsten Kennzahlen, Seite 252).',
            citation_how: 'Wie funktioniert die Methode?',
            citation2: 'Laut Peter Lynch: "Das KGV jedes korrekt bewerteten Unternehmens entspricht seiner Wachstumrate". Das Kurs-Gewinn-Wachstums-Verhältnis (Price to Earnings Growth Ratio - PEG-Ratio) ist das Kurs-Gewinn-Verhältnis (KGV) geteilt durch das Gewinnwachstum.',
            citation3: 'Damit haben wir einen einfache Kennzahl die uns sagt ob ein Unternehmen fair bewertet ist oder nicht. Die Nettoverschuldung wird dabei nicht berücksichtigt.',
          }
        },
        language: '',
      };
    },
    created() {
      this.language = sessionManager.read('language');
    },
    computed: {
      strings() {
          return this.languageStrings[this.language];
      },
    }
  }
</script>

<template>
  <PopupInfo v-bind:title="strings['title']" v-bind:showPopup="showPopup" v-bind:onClose="onClose">
    <template v-slot:content>
      <div class="text-darkest font-pj text-v-base font-bold py-v-md">{{ strings['explaination_title'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['explaination'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['explaination2'] }}</div>
      <div class="text-darkest font-pj text-v-base font-bold py-v-md">{{ strings['citation_title'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['citation'] }}</div>
      <div class="text-darkest font-pj text-v-base font-bold py-v-md">{{ strings['citation_how'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['citation2'] }}</div>
      <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['citation3'] }}</div>
    </template>
  </PopupInfo>
</template>
  
/* eslint-enable */ 
  