/* eslint-disable */
<script>
  import { sessionManager } from '@/main';
  import PopupInfo from '@/components/popup-info.vue';
 
  export default {
    name: 'CompanyValuationTenCapFreeCashflowInfo',
    props: {
      onClose: {
        type: Function,
        required: true,
      },
      showPopup: {
        type: Boolean,
        required: true,
      },
    },
    components: {
      PopupInfo,
    },
    data() {
      return {
        languageStrings: {
          en: {
            title: 'Ten-Cap Free Cashflow',   
            subtitle: 'Inspired by "Invested" by Danielle Town & Phil Town',
            explaination_title: 'Short explanation',
            explaination: 'Imagine you buy a printer that prints 100$ every year. How much would you pay for it?',
            explaination2: 'We think it is worth 10x 100$, so 1\'000$.',
            citation_title: 'How do we get there?',
            citation: 'In their book "Invested - How Warren Buffett and Charlie Munger taught me to master my mind, my emotions, and my money (with a little help from my dad)" '+
              'by Danielle Town and Phil Town the valuation method "Ten-Cap-Pricing Method" is described (ISBN 978-3-86470-604-2 Kapiel 7: Die Ten-Cap-Pricing Methode, page 225-242).',
            citation_how: 'How does the method work?',
            citation2: 'The Ten-Cap valuation method is an approach to valuation that helps investors determine the value of a company. ' +
              'It states that an investor should ideally not pay more than ten times the owner earnings of a company, hence the name "Ten-Cap".',
            citation3: 'Unlike the Ten-Cap Owner Earnings method, we use the free cashflow instead of the owner earnings and subtract the net debt at the end. ' +
              'We deviate from the original method because we believe that a valuation based on free cashflow provides additional insight into the value of a company.',
            citation4: 'In doing so, we deviate from the original method, as we believe that a valuation based on free cash flow provides additional insight into the value of a company.',
          },
          de: {
            title: 'Ten-Cap Free Cashflow',
            subtitle: 'Inspiriert durch "Gut Investiert" von Danielle Town & Phil Town',
            explaination_title: 'Kurz erklärt',
            explaination: 'Stell dir vor, es du kaufst einen Drucker der jedes Jahr 100$ druckt. Wie viel würdest Du dafür bezahlen?',
            explaination2: 'Wir denken, er ist 10x 100$ wert, also 1\'000$.',
            citation_title: 'Wie kommen wir darauf?',
            citation: 'In ihrem Buch "Gut Investiert - Wie Warren Buffett und Charlie Munger (und Paps*) mich lehrten, meine Gedanken, meine Emotionen und mein Geld unter Kontrolle zu bekommen" '+
              'von Danielle Town und Phil Town ist die Bewertungsmethode "Ten-Cap-Pricing Methode" beschrieben (ISBN 978-3-86470-604-2 Kapiel 7: Die Ten-Cap-Pricing Methode, Seite 225-242).',
            citation_how: 'Wie funktioniert die Methode?',
            citation2: 'Die Ten-Cap Bewertungsmethode ist ein Bewertungsansatz, der Anlegern hilft, den Wert eines Unternehmens zu bestimmen. ' +
              'Sie besagt, dass ein Anleger idealerweise nicht mehr als das Zehnfache des Eigentümergewinns oder Owner Earnings eines Unternehmens zahlen sollte, daher der Name "Ten-Cap".',
            citation3: 'Anders als bei der Ten-Cap Owner Earnings Methode verwenden wir bei der Ten-Cap Free Cashflow Methode den freien Cashflow anstelle des Eigentümergewinns und ziehen am Ende die Nettoverschuldung ab. ',
            citation4: 'Dabei weichen wir von der ursprünglichen Methode ab, da wir der Meinung sind, dass eine Bewertung aufgrund des freien Cashflows zusätzlichen Einblick in den Wert eines Unternehmens bietet.',
          }
        },
        language: '',
      };
    },
    created() {
      this.language = sessionManager.read('language');
    },
    computed: {
      strings() {
          return this.languageStrings[this.language];
      },
    }
  }
</script>
  
<template>
  <PopupInfo v-bind:title="strings['title']" v-bind:showPopup="showPopup" v-bind:onClose="onClose">
    <template v-slot:content>
      <div class="text-darkest font-pj text-v-base font-bold py-v-md">{{ strings['explaination_title'] }}</div>
          <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['explaination'] }}</div>
          <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['explaination2'] }}</div>
          <div class="text-darkest font-pj text-v-base font-bold py-v-md">{{ strings['citation_title'] }}</div>
          <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['citation'] }}</div>
          <div class="text-darkest font-pj text-v-base font-bold py-v-md">{{ strings['citation_how'] }}</div>
          <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['citation2'] }}</div>
          <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['citation3'] }}</div>
          <div class="text-darkest text-v-sm font-pj py-v-md">{{ strings['citation4'] }}</div>
    </template>
  </PopupInfo>
</template>
/* eslint-enable */ 
  